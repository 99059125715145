$(document).ready(function () {
    $('#lightgallery').lightGallery();
    $('.video-gallery').lightGallery({
        loadYoutubeThumbnail: true,
        youtubeThumbSize: 'default',
        selector: '.video__item',
        youtubePlayerParams: {
            modestbranding: 1,
            showinfo: 0,
            rel: 0,
            controls: 1
        },
    });


    jQuery('.team-slider').slick({
        dots: false,
        rtl: true,
        margin: 0,
        autoWidth: false,
        navigation: true,
        items: 6,
        loop: true,
        autoplay: false,
        slidesToShow: 5,
        autoplayTimeout: 3000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },

            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                }
            }

        ]
    });


});


$(document).ready(
    function () {
        var maxlen = 150;
        $('.desc').each(function () {
            var str = $(this).text();
            if ($.trim(str).length > maxlen) {
                var newStr = str.substring(0, maxlen);
                $(this).empty().html(newStr);
                $(this).append('...');

            }
        });
    });

$(document).ready(
    function () {
        var maxlen = 250;
        $('.text').each(function () {
            var str = $(this).text();
            if ($.trim(str).length > maxlen) {
                var newStr = str.substring(0, maxlen);
                $(this).empty().html(newStr);
                $(this).append('...');

            }
        });
    });

$('.open-nav').on('click', function (e) {
    e.preventDefault();
    $("#Sidenav").css('width', '70%');
    $("#overlay").css('display', 'block');
})


$('.close-nav').on('click', function (e) {
    e.preventDefault();
    $("#Sidenav").css('width', '0%');
    $("#overlay").css('display', 'none');
})


$('.open--block').on('click', function (e) {
    e.preventDefault();
    var x = document.getElementById("block");
    if (x.style.display === "block") {
        x.style.display = "none";
    } else {
        x.style.display = "block";
    }
})

$('.close--block').on('click', function (e) {
    document.getElementById("block").style.display = "none";
});
