import $ from 'jquery';
import './copmonents/bootstrap.min';
import 'lightgallery/dist/js/lightgallery.js'
import 'lightgallery/modules/lg-thumbnail'
import 'lightgallery/modules/lg-video'
import 'lightgallery/modules/lg-fullscreen'
import 'slick-carousel/slick/slick.js';
import 'jquery-mousewheel';
import 'lightgallery/dist/css/lightgallery.css'
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';
import '../styles/index.css'
import './main';

const _dir = $('html').attr('dir');

((
    ($) => {

        $('.publish-form').on('submit', function (e) {
            e.preventDefault();
            var _form = $(this);
            $.ajax({
                url: _form.attr('action'),
                data: _form.serialize(),
                method: "post",
                dataType: 'json',
                beforeSend: function () {
                    _form.find('.form-loader').addClass('is-visible');
                    _form.find('.help-block').remove();
                    _form.find('.has-error').removeClass('has-error');
                },
                success: function (results) {
                    _form.find('input, select, textarea').val("");
                    _form.prepend(`<div class="aler alert-success">${application.translations.publish_form_success}</div>`)
                },
                error: function (response) {
                    let _response = response.responseJSON;
                    let _errors = _response.errors;
                    $('.required-label').remove();
                    // console.log(_errors);
                    $.each(_errors, function (_element, _message) {
                        let _field = $('[name="' + _element + '"]');
                        let _requiredLabelWrapper = _field.parents('.form-group');
                        _requiredLabelWrapper.addClass('has-error')
                        _requiredLabelWrapper.append('<append class="help-block">' + _message + '</append>');
                    });
                    if (typeof window.grecaptcha !== 'undefined') {
                        window.grecaptcha.reset();
                    }
                },
                complete: function () {
                    _form.find('.form-loader').removeClass('is-visible');
                }
            });

        })

    }
)(jQuery));
